<template>
  <div id="">
    <div
      class="search-container"
      style="margin: 0 auto; width: 80%; margin-top: -4px"
    >
      <div class="row justify-content-between position-relative">
        <!-- <div class="">
          <img src="../../assets/img/search/3.0sxtk_search_icon_search.png" alt="" />
        </div> -->
        <div class="check-items">
          <el-select
            @focus="radioClose()"
            v-model="companyType"
            @change="changeCom"
          >
            <!-- <el-option value="0" label="上市企业"></el-option> -->
            <el-option value="1" label="非上市企业"></el-option>
          </el-select>
        </div>
        <div class="col">
          <!-- <input
            type="text"
            class="col"
            placeholder="请输入公司名称、股票代码"
            id="inputText"
            @click.stop
            @keydown.down="keyDownChoose"
            @keydown.up="keyUpChoose"
            v-model="searchText"
            @keyup="queryCompany"
            @focus="inputFous"
          /> -->
          <input
            type="text"
            class="col"
            style="width: 100%"
            placeholder="请输入公司名称、股票代码"
            id="inputText"
            @click.stop
            @keydown.down="keyDownChoose"
            @keydown.up="keyUpChoose"
            v-model="searchText"
            @keyup="queryCompany"
            @focus="inputFous"
          />
        </div>
        <div
          class="cursor_pionter search_s color-4d justify-content-evenly"
          style="
            line-height: 52px;
            width: 154px;
            height: 48px;
            background-image: linear-gradient(90deg, #3c9bfe 7%, #3a85fd 100%);
            border-radius: 0px 26px 26px 0px;
            padding-left: 20px;
          "
          @click.stop="searchCompany"
        >
          <i style="color: #ffffff" class="el-icon-search"></i>
          <span
            style="
              width: 48px;
              height: 24px;
              font-family: PingFangSC-Medium;
              font-size: 18px;
              color: #ffffff;
              text-align: center;
              line-height: 24px;
              font-weight: 500;
              padding-left: 20px;
            "
            >搜 索</span
          >
        </div>
        <div
          class="position-absolute search-list"
          v-show="
            searchText != '' &&
            searchLists.errMessage != '没有查询到结果！' &&
            searchGood
          "
        >
          <template v-for="(item, index) in searchLists">
            <a
              href="javascript:void(0)"
              v-if="index < 8"
              :key="index"
              class="d-block mt-2 search-items"
              style="display: flex !important; align-items: center"
              @mouseover="mouseClearStyle"
              @click="stepToCompanyInfo(item)"
            >
              <span class="color-4d search-hot-title d-inline-block text-center"
                >公司</span
              >
              <span
                class="color-4d ml-4 fz-14"
                style="
                  width: 251px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;
                  white-space: nowrap;
                "
                >{{ item.name }}</span
              >
              <span
                class="color-4d fz-14 text-center"
                style="position: relative; width: 200px; display: inline-block"
                >({{ item.create_time }})</span
              >
            </a>
          </template>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="w-100 mt-5">
        <div class="fz-14 color-4d">
          关于<span class="color-le fz-16" style="padding: 0 4px">{{
            keyword
          }}</span
          >的搜索共<span>{{ total }}</span
          >条
        </div>
        <div class="bgc_w pt-10 pb-5 pl-4 pr-4">
          <div
            class="test pb-4 pt-3 justify-content-start"
            v-for="(company, key) in storeAll"
            :key="key"
          >
            <div class="col-md-10 col-sm-12">
              <div class="row align-items-center">
                <span
                  class="row align-items-center fz-24 color-0"
                  @click="toCompanyDetail(company)"
                  >{{ company.name }}</span
                >
                <span
                  class="
                    fz-14
                    color-4ad
                    pt-1
                    pb-1
                    pl-2
                    pr-2
                    bgc-df
                    br_25
                    ml-2
                    mr-5
                  "
                  >我的企业</span
                >
              </div>
              <div class="row align-items-center mt-2">
                <div class="color-4d">
                  <span class="">统计局行业:</span>
                  <span>{{ company.tj_category.industry_name }}</span>
                </div>

                <span class="color-4d ml-3 fz-14">{{
                  company.create_time
                }}</span>
              </div>
            </div>
          </div>
          <div class="text-center bottom-right">
            <v-pagination
              :total="total"
              @page-change="pageChange"
              @page-size-change="pageSizeChange"
              size="middle"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as sesstion from "../../store/localStorage";
import { mapGetters, mapState, mapActions } from "vuex";
import { yearList } from "@/api";
export default {
  name: "smallCompanySearch",
  dev: "小微企业搜索",
  components: {},
  data() {
    return {
      companyType: "1",
      searchLists: [],
      searchText: "",
      searchGood: true,

      hotStand: 50,
      num: 0,
      array: [],
      lessArrays: [],
      isAdd: false,
      storeAll: [],
      total: 0, // 记录数据的总条数
      display: 10, // 每页显示数据的条数
      current: 1, // 当前的页数
      keyword: "",
    };
  },
  created() {
    $("body").click(function () {
      $(".search-list").css("display", "none");
    });
    this.$store.state.headLogo = true;
    this.keyword = sesstion.getLocalData("searchText");
    this.queryCompanyList(1);
  },
  methods: {
    ...mapActions([
      "queryPutData",
      "keyDownChoose",
      "keyUpChoose",
      "saveClickOrHoverIndex",
      "mouseClearStyle",
    ]),
    pageSizeChange(pageSize) {
      //console.log(pageSize)
    },
    pageChange(pageIndex) {
      this.queryCompanyList(pageIndex);
    },
    queryCompanyList(page) {
      var value = this.keyword;
      this.$http
        .get(
          window.BASE_URL +
            "/company/my/search-paging/" +
            value +
            "/" +
            0 +
            "/?page=" +
            page,
          {
            headers: {
              Authorization:
                "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
            },
          }
        )
        .then(
          (res) => {
            this.storeAll = res.data.companyList;
            //console.log( this.storeAll)
            this.total = res.data.total;
          },
          (res) => {
            // console.log("请求失败！");
          }
        );
    },
    queryCompany() {
      var value = this.searchText;
      if (value != "") {
        this.$http
          .get(
            window.BASE_URL + "/company/my/search/" + value + "/" + 0 + "/",
            {
              headers: {
                Authorization:
                  "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
              },
            }
          )
          .then(
            (res) => {
              this.searchLists = res.data;
            },
            (res) => {
              // console.log("请求失败！");
            }
          );
      }
    },
    jumpPage(item) {
      sesstion.setLocalData("company", item);
      this.$router.push({ name: "CompanyDetail" });
    },
    stepToCompanyInfo(item) {
      yearList({ company_code: item.company_code, menu_name: "simple" }).then(
        ({ data }) => {
          if (data.Status == 0) {
            this.$message.error(data.Message);
          } else if (data.Status == 1) {
            this.$confirm(data.Message, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.jumpPage(item);
              })
              .catch(() => {});
          } else {
            this.jumpPage(item);
          }
        }
      );
    },
    searchCompany() {
      var value = this.searchText;
      sesstion.setLocalData("searchText", value);
      this.keyword = value;
      this.searchGood = false;
      this.$http
        .get(
          window.BASE_URL +
            "/company/my/search-paging/" +
            value +
            "/" +
            0 +
            "/?page=1",
          {
            headers: {
              Authorization:
                "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
            },
          }
        )
        .then(
          (res) => {
            this.storeAll = res.data.companyList;
            //console.log( this.storeAll)
            this.total = res.data.total;
          },
          (res) => {
            // console.log("请求失败！");
          }
        );
    },
    inputFous() {
      var value = this.searchText;
      if (value != "") {
        $(".search-list").css("display", "block");
      }
    },
    add(id) {
      this.isAdd = !this.isAdd;
    },
    toCompanyDetail(item) {
      yearList({ company_code: item.company_code, menu_name: "simple" }).then(
        ({ data }) => {
          if (data.Status == 0) {
            this.$message.error(data.Message);
          } else if (data.Status == 1) {
            this.$confirm(data.Message, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.jumpPage(item);
              })
              .catch(() => {});
          } else {
            this.jumpPage(item);
          }
        }
      );
    },
  },
  computed: {
    ...mapGetters({
      keyDownNows: "keyDownNow",
    }),
    hot: function () {
      let f = this.hotStand;
      if (0 <= f <= 20) {
        return 1;
      } else if (20 < f <= 40) {
        return 2;
      } else if (40 < f <= 60) {
        return 3;
      } else if (60 < f <= 80) {
        return 4;
      } else {
        return 5;
      }
    },
    hotArray: function () {
      this.array.length = this.hot;
      return this.array;
    },
    lessArray: function () {
      this.lessArrays.length = 5 - this.hot;
      return this.lessArrays;
    },
    cp: function () {
      if (this.isAdd == false) {
        return "收藏";
      } else {
        return "已收藏";
      }
    },
    contrast: function () {
      return "对比";
    },
  },
};
</script>

<style lang="less" scoped>
.fontA {
  font-family: ArialMT;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
}
.bottom-right {
  right: 20px;
  position: absolute;
  bottom: 0px;
}
.bgc_w {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 2rem;
}
.test {
  width: 48%;
  /* height: 2.7rem; */
  /* position: relative; */
  /* width: 750px; */
  /* height: 124px; */
  background: #ffffff;
  border-radius: 16px;
  /* border: 1px solid red; */
  margin: 10px;
}
.btn-addCompany {
  width: 140px;
  height: 36px;
  line-height: 36px;
  color: #1270ff !important;
  border: 1px solid #1270ff;
}
.el-input__inner {
  margin: 0 10px;
}
.fz-14 {
  margin-left: 70px;
}
.search-list {
  left: 0;
  top: 49px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  z-index: 1;
}
.check-items {
  width: 130px;
  height: 48px;
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  line-height: 48px;
  font-weight: 550;
  text-align: center;
}
.el-input__inner {
  border: none;
}
.el-select .el-input__inner {
  font-size: 16px;
  font-weight: 550;
}
.el-select .el-input .el-select__caret {
  height: 40px;
}
</style>
